// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-design-index-js": () => import("./../../../src/pages/work/design/index.js" /* webpackChunkName: "component---src-pages-work-design-index-js" */),
  "component---src-pages-work-design-logos-chuko-mdx": () => import("./../../../src/pages/work/design/logos/chuko.mdx" /* webpackChunkName: "component---src-pages-work-design-logos-chuko-mdx" */),
  "component---src-pages-work-design-logos-melange-mdx": () => import("./../../../src/pages/work/design/logos/melange.mdx" /* webpackChunkName: "component---src-pages-work-design-logos-melange-mdx" */),
  "component---src-pages-work-design-logos-tinkerman-mdx": () => import("./../../../src/pages/work/design/logos/tinkerman.mdx" /* webpackChunkName: "component---src-pages-work-design-logos-tinkerman-mdx" */),
  "component---src-pages-work-design-others-daily-ui-js": () => import("./../../../src/pages/work/design/others/daily-ui.js" /* webpackChunkName: "component---src-pages-work-design-others-daily-ui-js" */),
  "component---src-pages-work-design-others-flyers-js": () => import("./../../../src/pages/work/design/others/flyers.js" /* webpackChunkName: "component---src-pages-work-design-others-flyers-js" */),
  "component---src-pages-work-design-others-print-mdx": () => import("./../../../src/pages/work/design/others/print.mdx" /* webpackChunkName: "component---src-pages-work-design-others-print-mdx" */),
  "component---src-pages-work-writing-digital-index-js": () => import("./../../../src/pages/work/writing/digital/index.js" /* webpackChunkName: "component---src-pages-work-writing-digital-index-js" */),
  "component---src-pages-work-writing-free-verse-index-js": () => import("./../../../src/pages/work/writing/free-verse/index.js" /* webpackChunkName: "component---src-pages-work-writing-free-verse-index-js" */),
  "component---src-pages-work-writing-index-js": () => import("./../../../src/pages/work/writing/index.js" /* webpackChunkName: "component---src-pages-work-writing-index-js" */),
  "component---src-pages-work-writing-radio-index-js": () => import("./../../../src/pages/work/writing/radio/index.js" /* webpackChunkName: "component---src-pages-work-writing-radio-index-js" */),
  "component---src-pages-work-writing-spoken-word-index-js": () => import("./../../../src/pages/work/writing/spoken-word/index.js" /* webpackChunkName: "component---src-pages-work-writing-spoken-word-index-js" */),
  "component---src-pages-work-writing-ux-daily-mdx": () => import("./../../../src/pages/work/writing/ux/daily.mdx" /* webpackChunkName: "component---src-pages-work-writing-ux-daily-mdx" */),
  "component---src-pages-work-writing-ux-handshake-mdx": () => import("./../../../src/pages/work/writing/ux/handshake.mdx" /* webpackChunkName: "component---src-pages-work-writing-ux-handshake-mdx" */),
  "component---src-pages-work-writing-ux-suane-mdx": () => import("./../../../src/pages/work/writing/ux/suane.mdx" /* webpackChunkName: "component---src-pages-work-writing-ux-suane-mdx" */)
}

